<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <div v-if="edit">
            <van-cell-group inset >
                <van-form>
                    <select-component
                        name="enterprise_id"
                        lable="申请企业"
                        :farr="all_enterprise"
                        :fvalue_name="details.enterprise_name"
                        @clk="confirm_enterprise"
                    ></select-component>
                    <select-component
                        name="department_id"
                        lable="申请部门"
                        :farr="all_department"
                        :fvalue_name="details.department_name"
                        @clk="confirm_department"
                    ></select-component>
                    <van-field
                        v-model="details.title"
                        name="title"
                        label="差旅标题"
                        placeholder="差旅标题"
                        :rules="[{ required: true, message: '请填写差旅标题' }]"
                    />
                    <van-field
                        type="textarea"
                        v-model="details.content"
                        name="content"
                        label="差旅原由"
                        placeholder="差旅原由"
                        :rules="[{ required: true, message: '请填写差旅原由' }]"
                    />
                    <van-field
                        type="textarea"
                        v-model="details.trip"
                        name="trip"
                        label="行程安排"
                        placeholder="行程安排"
                        :rules="[{ required: true, message: '请填写行程安排' }]"
                    />
                    <select-component
                        name="address_id"
                        lable="目的地"
                        :farr="all_address"
                        :fvalue_name="details.address_name"
                        @clk="confirm_address"
                    ></select-component>
                    <date-component
                        title="开始日期"
                        @clk="confirm_start_time"
                    ></date-component>
                    <date-component
                        title="结束日期"
                        @clk="confirm_end_time"
                    ></date-component>
                    <number-keyboard-component
                        name="days"
                        label="出差天数"
                        extra_key="."
                        right_icon="天"
                        placeholder="出差天数"
                        :fvalue="details.days"
                        @on_input="confirm_days"
                    ></number-keyboard-component>
                    <number-keyboard-component
                        name="travel_money"
                        label="差旅预计花费"
                        extra_key="."
                        right_icon="元"
                        placeholder="请输金额"
                        :fvalue="details.travel_money"
                        @on_input="confirm_travel_money"
                    ></number-keyboard-component>
                    <van-cell title="是否需要招待">
                        <template #right-icon>
                            <van-radio-group v-model="details.contain_entertain" direction="horizontal" @change="contain_entertain_change">
                                <van-radio :name="0" checked-color="#d0d0d0">不需要</van-radio>
                                <van-radio :name="1">需要</van-radio>
                            </van-radio-group>
                        </template>
                    </van-cell>
                    <div v-if="details.contain_entertain">
                        <van-field
                            type="textarea"
                            v-model="details.entertain_content"
                            name="content"
                            label="招待目的"
                            placeholder="招待目的"
                            :rules="[{ required: true, message: '请填写招待目的' }]"
                        />
                        <select-component
                            name="entertain_client_id"
                            lable="招待客户"
                            :farr="all_entertain_client"
                            :fvalue_name="details.entertain_client_name"
                            @clk="confirm_entertain_client"
                        ></select-component>
                        <date-component
                            title="招待日期"
                            @clk="confirm_entertain_date"
                        ></date-component>
                        <number-keyboard-component
                            name="entertain_client_num"
                            label="招待人数"
                            extra_key="00"
                            right_icon="人"
                            placeholder="请输入人数"
                            :fvalue="details.entertain_client_num"
                            @on_input="confirm_entertain_client_num"
                        ></number-keyboard-component>
                        <van-field
                            type="textarea"
                            v-model="details.entertain_goods"
                            name="content"
                            label="招待物资需求"
                            placeholder="招待物资需求"
                            :rules="[{ required: true, message: '请填写招待物资需求' }]"
                        />
                        <number-keyboard-component
                            name="entertain_money"
                            label="招待预计花费"
                            extra_key="."
                            right_icon="元"
                            placeholder="请输入金额"
                            :fvalue="details.entertain_money"
                            @on_input="confirm_entertain_money"
                        ></number-keyboard-component>
                    </div>
                    <van-field
                        type="textarea"
                        v-model="details.money_note"
                        name="money_note"
                        label="费用事项"
                        placeholder="费用事项"
                        :rules="[{ required: true, message: '请填写费用事项' }]"
                    />
                    <van-cell title="总预算金额">{{details.money}}</van-cell>
                    <select-component
                        name="staff_id"
                        lable="申请人"
                        :farr="all_staff"
                        :fvalue_name="details.staff_name"
                        @clk="confirm_staff"
                    ></select-component>
                    <select-component
                        name="entourage_id"
                        lable="随行人"
                        :farr="all_staff"
                        :fvalue_name="details.entourage_name"
                        @clk="confirm_entourage_id"
                    ></select-component>
                    <van-cell title="上传附件">
                        <template #label>
                            <uploader-component
                                :farr="details.other"
                                url="travel/upload"
                                @update_img="update_img"
                            ></uploader-component>
                        </template>
                    </van-cell>
                </van-form>
                <div v-if="this.id">
                    <div class="sub_button">
                        <van-button round block type="primary" @click="edit_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="back_details">取 消</van-button>
                    </div>
                </div>
                <div v-else>
                    <div class="sub_button">
                        <van-button round block type="primary" @click="add_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="$router.go(-1)">返回</van-button>
                    </div>
                </div>
            </van-cell-group>
        </div>
        <div v-else>
            <van-cell-group inset >
                <van-cell title="差旅单号" :value="details.name" />
                <van-cell title="状态">
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="details.state===0">待提交</van-tag>
                        <van-tag plain type="primary" v-if="details.state===1">审批中</van-tag>
                        <van-tag plain type="success" v-if="details.state===2">已通过</van-tag>
                        <van-tag plain type="danger" v-if="details.state===3">已驳回</van-tag>
                    </template>
                </van-cell>
                <van-cell title="申请企业" :value="details.enterprise_name" />
                <van-cell title="申请部门" :value="details.department_name" />
                <van-cell title="差旅标题" :value="details.title" />
                <van-cell title="差旅原由" :value="details.content" />
                <van-cell title="行程安排" :value="details.trip" />
                <van-cell title="目的地" :value="details.address_name" />
                <van-cell title="开始日期" :value="details.start_time" />
                <van-cell title="结束日期" :value="details.end_time" />
                <van-cell title="出差天数" :value="details.days+'天'" />
                <van-cell title="差旅预计花费" :value="details.travel_money | diy_money" />
                <van-cell title="是否需要招待">
                    <template #right-icon>
                        <van-tag plain color="#d0d0d0" v-if="details.contain_entertain===0">不需要</van-tag>
                        <van-tag plain type="success" v-if="details.contain_entertain===1">需要</van-tag>
                    </template>
                </van-cell>
                <div v-if="details.contain_entertain">
                    <van-cell title="招待目的" :value="details.entertain_content" />
                    <van-cell title="招待客户" :value="details.entertain_client_name" />
                    <van-cell title="招待日期" :value="details.entertain_date" />
                    <van-cell title="预计招待人数" :value="details.entertain_client_num+'人'" />
                    <van-cell title="招待物资需求" :value="details.entertain_goods" />
                    <van-cell title="招待预计花费" :value="details.entertain_money | diy_money" />
                </div>
                <van-cell title="费用事项" :value="details.money_note" />
                <van-cell title="总预算金额" :value="details.money | diy_money" />
                <van-cell title="申请人" :value="details.staff_name" />
                <van-cell title="随行人" :value="details.entourage_name" />
                <van-cell title="附件">
                    <template #label>
                        <uploader-component
                            :farr="details.other"
                            :show_upload="false"
                            :deletable="false"
                        ></uploader-component>
                    </template>
                </van-cell>
            </van-cell-group>
            <div class="sub_button">
                <van-button v-if="to_approval_auth" round block type="primary" @click="to_approval()">提交审批</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="sel_approval_auth" round block type="warning" @click="sel_approval()">查看审批</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="withdraw_auth" round block type="danger" @click="travelWithdraw()">审批撤回</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="edit_auth" round block type="info" @click="edit = true">修 改</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="del_auth" round block type="danger" @click="on_del">删 除</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import NumberKeyboardComponent from '@/components/common/NumberKeyboardComponent.vue'
import UploaderComponent from '@/components/common/UploaderComponent.vue'
import DateComponent from '@/components/common/DateComponent.vue'

import { 
    travel_details_request,
    travel_del_request,
    travel_edit_request,
    travel_add_request,
    travel_to_approval_request,
    travel_withdraw_request
    } from '@/network/finance/Travel.js'
import { enterprise_list_request,department_list_request,staff_list_request,entertain_client_list_request,travel_address_list_request } from '@/network/list.js'
import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name:'TravelDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            id: 0,
            details: {
                enterprise_id: this.$store.state.user.enterprise_id,
                enterprise_name: this.$store.state.user.enterprise_name,
                department_id: this.$store.state.user.department_id,
                department_name: this.$store.state.user.department_name,
                title: '',
                content: '',
                trip: '',
                address_id: '',
                start_time: '',
                end_time: '',
                days: '',
                travel_money: '',
                contain_entertain: 0,
                entertain_content: '',
                entertain_client_id: '',
                entertain_date: '',
                entertain_client_num: '',
                entertain_goods: '',
                entertain_money: '',
                money_note: '',
                money: '',
                staff_id: this.$store.state.user.id,
                staff_name: this.$store.state.user.name,
                entourage_id: [],
                other: []
            },
            edit: false,
            all_enterprise: [],
            all_department: [],
            all_staff: [],
            all_entertain_client: [],
            all_address: [],
            edit_auth: false,
            del_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false
        }
    },
    computed:{
        my_nav_bar_name() {
            return this.edit === true ? ( this.id ? '修改差旅单' : '新增差旅单') : '差旅单详情'
        }
    },
    methods:{
        get_travel_details() {
            this.$store.commit('true_loading')
            travel_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => {
                    this.$store.commit('false_loading')
                    this.is_auth()
                })
        },
        on_del() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                travel_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('删除成功!')
                                this.$router.go(-1);//返回上一层
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        back_details() {
            this.get_travel_details()
            this.edit = false
        },
        edit_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                id: this.id,
                department_id: this.details.department_id,
                title: this.details.title,
                content: this.details.content,
                trip: this.details.trip,
                address_id: this.details.address_id,
                start_time: this.details.start_time,
                end_time: this.details.end_time,
                days: this.details.days,
                travel_money: this.details.travel_money,
                contain_entertain: this.details.contain_entertain,
                entertain_content: this.details.entertain_content,
                entertain_client_id: this.details.entertain_client_id,
                entertain_date: this.details.entertain_date,
                entertain_client_num: this.details.entertain_client_num,
                entertain_goods: this.details.entertain_goods,
                entertain_money: this.details.entertain_money,
                money_note: this.details.money_note,
                money: this.details.money,
                staff_id: this.details.staff_id,
                entourage_id: this.details.entourage_id,
                other: this.details.other
            }
            travel_edit_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('修改成功!')
                        this.get_travel_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        add_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                department_id: this.details.department_id,
                title: this.details.title,
                content: this.details.content,
                trip: this.details.trip,
                address_id: this.details.address_id,
                start_time: this.details.start_time,
                end_time: this.details.end_time,
                days: this.details.days,
                travel_money: this.details.travel_money,
                contain_entertain: this.details.contain_entertain,
                entertain_content: this.details.entertain_content,
                entertain_client_id: this.details.entertain_client_id,
                entertain_date: this.details.entertain_date,
                entertain_client_num: this.details.entertain_client_num,
                entertain_goods: this.details.entertain_goods,
                entertain_money: this.details.entertain_money,
                money_note: this.details.money_note,
                money: this.details.money,
                staff_id: this.details.staff_id,
                entourage_id: this.details.entourage_id,
                other: this.details.other
            }
            travel_add_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('添加成功!')
                        this.id = s.result.id
                        this.get_travel_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        sel_approval() {
            this.$router.push({
                name: 'ApprovalComponent',
                query: {
                    type: 6,
                    id: this.id,
                    ApprovalAuth: this.ApprovalAuth
                }
            })
        },
        to_approval() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定提交审批？',
            })
            .then(() => {
                this.$store.commit('true_loading')
                travel_to_approval_request({id:this.id,note:''})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已提交!')
                                this.get_travel_details()
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        travelWithdraw() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定撤回？（撤回后再发起需要重新走流程）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                travel_withdraw_request({id:this.id})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已撤回!')
                                this.get_travel_details()
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        get_all_enterprise() {
            this.$store.commit('true_loading')
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_enterprise = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_all_staff() {
            this.$store.commit('true_loading')
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_staff = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_all_entertain_client() {
            this.$store.commit('true_loading')
            entertain_client_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_entertain_client = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_all_address() {
            this.$store.commit('true_loading')
            travel_address_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_address = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        contain_entertain_change(e) {
            if(e===0) {
                this.details.entertain_content = ''
                this.details.entertain_client_id = ''
                this.details.entertain_date = ''
                this.details.entertain_client_num = ''
                this.details.entertain_goods = ''
                this.details.entertain_money = ''
            }
        },
        sum_money() {
            this.details.money = this.details.travel_money*1 + this.details.entertain_money*1
        },
        confirm_enterprise(e) {
            this.details.enterprise_id = e.id
            this.details.department_id = ''
            department_list_request({enterprise_id: e.id})
                .then((s) => {
                    if (s.status === 0) {
                        this.all_department = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_department(e) {
            this.details.department_id = e.id
        },
        confirm_entertain_client(e) {
            this.details.entertain_client_id = e.id
        },
        confirm_staff(e) {
            this.details.staff_id = e.id
        },
        confirm_address(e) {
            this.details.address_id = e.id
        },
        confirm_travel_money(e) {
            this.details.travel_money = e
            this.sum_money()
        },
        confirm_start_time(e) {
            this.details.start_time = e
        },
        confirm_end_time(e) {
            this.details.end_time = e
        },
        confirm_days(e) {
            this.details.days = e
        },
        confirm_entourage_id(e) {
            this.details.entourage_id = [e.id]
        },
        confirm_entertain_date(e) {
            this.details.entertain_date = e
        },
        confirm_entertain_client_num(e) {
            this.details.entertain_client_num = e
        },
        confirm_entertain_money(e) {
            this.details.entertain_money = e
            this.sum_money()
        },
        update_img(arr) {
            this.details.other = arr
        },
        is_auth() {
            this.edit_auth = false
            this.del_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false

            if(this.details.state === 0) {
                //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交差旅申请')
                    this.edit_auth = this.$_has('修改差旅申请')
                    this.del_auth = this.$_has('删除差旅申请')
                }
            } else if(this.details.state === 1) {
                 //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回差旅申请')
                }
                //判断当前审批人是否为自己
                if(this.details.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批差旅申请')
                }
                this.sel_approval_auth = this.$_has('查看差旅申请审批记录')
            } else if(this.details.state === 2) {
                this.sel_approval_auth = this.$_has('查看差旅申请审批记录')
            } else if(this.details.state === 3) {
                //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交差旅申请')
                    this.edit_auth = this.$_has('修改差旅申请')
                    this.del_auth = this.$_has('删除差旅申请')
                }
                this.sel_approval_auth = this.$_has('查看差旅申请审批记录')
            }
        },
    },
    filters:{
        diy_money(v) {
            return v ? '￥ '+thousandBitSeparator(fomatFloat(v)) : ''
        }
    },
    props:{},
    created(){
        this.id = this.$route.query.id
        if(this.id) {
            this.get_travel_details()
        }
        else
        {
            this.edit = true
        }

        this.get_all_enterprise()
        this.get_all_staff()
        this.get_all_entertain_client()
        this.get_all_address()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        NumberKeyboardComponent,
        UploaderComponent,
        DateComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
.van-cell__value {
    white-space: pre-line
}
</style>