<template>
    <div>
        <van-cell
            :title="title"
            :value="date"
            @click="show = true"
        />
        <van-calendar
            v-model="show"
            color="#1989fa"
            :min-date="min_date"
            :show-confirm="false"
            :formatter="formatter"
            @confirm="confirm"
        />
    </div>
</template>

<script>
import { formatDate } from '@/assets/js/common.js'

export default {
    name:'DateComponent',
    data(){
        return {
            show: false,
            date: ''
        }
    },
    computed:{},
    methods:{
        confirm(e) {
            this.show = false
            this.date = formatDate(e)
            this.$emit('clk',this.date)
        },
        formatter(day) {
            return day
        }
    },
    filters:{},
    props:{
        title: {
            type: String,
            default() {
                return '名称'
            }
        },
        min_date: {
            type: Date,
            default() {
                return new Date(2020, 0, 1)
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style>
</style>